<template>
  <main>
    <div class="view-catalog-checkout container">
      <div class="view-catalog-checkout__header">
        <actions-button
          :appearance="$pepper.Appearance.SUBTLE"
          class="view-catalog-checkout__back-button"
          icon-pre="arrow-left"
          :size="$pepper.Size.S"
          @click="close">
          <span class="view-catalog-checkout__title">{{ $t('conn3ct-store.checkout-title') }}</span>
        </actions-button>

        <!-- <h3 class="view-catalog-checkout__title">{{ $t('conn3ct-store.checkout-title') }}</h3> -->
      </div>

      <div
        class="view-catalog-checkout__loader"
        v-if="loading">
        <ui-loader />
      </div>

      <article
        class="view-catalog-checkout__product"
        v-if="!loading">
        <div class="view-catalog-checkout__product-illustration">
          <ui-image
            fit="cover"
            :src="figure"
            url-complete
            v-if="!hasFigure && imageType === 'image'"
          />

          <video
            class="view-catalog-checkout__product-video"
            autoplay
            loop
            muted
            playsinline
            :src="figure"
            v-if="!hasFigure && imageType === 'video'"
          />
        </div>

        <div class="view-catalog-checkout__product-details">
          <h3 class="name">{{ name }}</h3>

          <product-price
            class="price"
            :product="product"
            :size="$pepper.Size.S"
            :taggable="false"
          />
        </div>
      </article>

      <transition-group
        class="flow view-catalog-checkout__checkout"
        tag="div"
        v-if="!loading">
        <component
          :disabled="isEditing"
          :key="step.name"
          :is="step.component"
          v-bind="step.attrs"
          v-for="step in displayedCards"
        />
      </transition-group>
    </div>
  </main>
</template>

<script>
import ErrorsMixin from '@/helpers/errors'
import StepsMixin from '../mixins/steps'

import ProductPrice from '../components/product/price'
import UiImage from '@/components/ui/image'

export default {
  name: 'DirectCheckout',

  components: {
    UiImage,
    ProductPrice,
  },

  inject: [
    '$catalog', 
    '$checkout',
    '$embed',
    '$nft',
  ],

  mixins: [
    ErrorsMixin,
    StepsMixin,
  ],

  data() {
    return {
      errors: {},
      isEditing: false,
      loading: true,
      product: null,
      key: 1
    }
  },

  computed: {
    classes() {
      return {
        'view-catalog-checkout': true
      }
    },

    imageType() {
      const type = this.$basil.get(this.product, 'imageType')

      if(type && type.includes('video')) {
        return 'video'
      }

      if(type && type.includes('image')) {
        return 'image'
      }

      return 'image'
    },

    figure() {
      return this.$basil.get(this.product, 'picture', null)
    },

    hasFigure() {
      return this.$basil.isNil(this.figure)
    },

    name() {
      return this.$basil.get(this.product, 'name', null)
    },

    order() {
      return this.$checkout.order
    },

    productId() {
      return this.$basil.get(this.$route, 'params.product').split('.')[0]
    },

    serial() {
      const parts = this.$basil.get(this.$route, 'params.product').split('.')
      return parts.length > 1 ? parseInt(parts[1], 10) : null
    }
  },

  methods: {
    async close() {
      try {
        this.loading = true

        await this.$checkout.cancel()
      } catch(e) {
        $console.error(e)
      } finally {
        this.$router.push({
          name: 'sayl-front-catalog.product',
          params: {
            embed: this.$route.params.embed,
            product: this.productId.split('.')[0]
          }
        }).catch(() => {})

        this.loading = false
      }
    }
  },

  async mounted() {
    try {
      this.loading = true
      this.errors = {}

      let item = {
        product_id: this.productId,
        quantity: 1,
        type: 'nft'
      }

      if (this.serial) {
        item.nft_serial = this.serial
      }
      
      await this.$checkout.directCheckout({
        items: [item],
        service: 'virtual',
        product: 'conn3ct',
        embed: this.$embed
      })

      this.product = this.$nft.findById(this.productId)
      let prod = this.$catalog.product.getProductById(this.productId)
      this.product.mergeAdditionnalData(prod)
    } catch(e) {
      this.handleErrors(e)
      this.errors = e
      
      if(e.status && e.status === 422) {
        let descr = []
        
        if(Object.keys(e._items)) {
          Object.keys(e._items).forEach(k => {
            descr.push(...this.getErrors(k))
          })
        }

        this.$confirm({
          title: this.$t('conn3ct-store.checkout_422_error_title'),
          description: descr.join(', '),
          secondaryAction: this.$t('conn3ct-store.back'),
          primaryAction: null,
          onSecondary: () => {
            this.close()
          }
        })
      } else {
        this.close()
      }
    } finally {
      this.loading = false
    }
  }
}
</script>
