<template>
  <!-- Subscribe -->
  <div
    :class="classes"
    v-if="!loading">
    <!-- Error banner -->
    <ui-banner
      :description="errorsLogin"
      intent="danger"
      v-if="errors['login']"
    />

    <form
      class="user-forms-subscribe__body flow"
      @submit="onSubmit">
      <!-- Guest form -->
      <div class="user-forms-subscribe__guest flow">
        <forms-input
          :autofocus="autofocus"
          autocomplete
          :errors="getErrors('firstname')"
          name="given-name"
          :placeholder="$t('conn3ct-store.user_firstname_placeholder')"
          ref="firstname"
          :required="isFieldFirstnameRequired"
          :size="$pepper.Size.L"
          @input="onRemoveError('firstname')"
          v-model="user.firstname"
        >{{ $t('conn3ct-store.user_firstname') }}</forms-input>

        <forms-input
          autocomplete
          :required="isFieldLastnameRequired || isCreating || !hasGuest"
          :errors="getErrors('lastname')"
          name="family-name"
          ref="lastname"
          :placeholder="$t('conn3ct-store.user_lastname_placeholder')"
          :size="$pepper.Size.L"
          @input="onRemoveError('lastname')"
          v-model="user.lastname"
        >{{ $t('conn3ct-store.user_lastname') }}</forms-input>

        <template v-if="isFieldEmailActive || isFieldPhoneActive || !isCreating">
          <!-- Email -->
          <forms-input
            autocomplete
            :errors="getErrors('email')"
            name="email"
            :placeholder="$t('conn3ct-store.user_email_placeholder')"
            ref="email"
            :required="isFieldEmailRequired || !hasGuest"
            :size="$pepper.Size.L"
            type="email"
            @input="onRemoveError('email')"
            v-if="isFieldEmailActive || !hasGuest"
            v-model="user.email"
          >{{ $t('conn3ct-store.user_email') }}</forms-input>

          <!-- Phone -->
          <forms-input
            autocomplete
            :errors="getErrors('phone')"
            :hint="$t('conn3ct-store.user_phone_number_hint')"
            name="phone"
            :placeholder="$t('conn3ct-store.user_phone_number_placeholder')"
            ref="phone"
            :required="isFieldPhoneRequired"
            :size="$pepper.Size.L"
            @input="onRemoveError('phone')"
            v-if="isFieldPhoneActive || !hasGuest"
            v-model="user.phone"
          >{{ $t('conn3ct-store.user_phone_number') }}</forms-input>
        </template>
      </div>
    </form>
  </div>
</template>

<script>
import FieldsMixin from '@/modules/catalog/mixins/fields'
import ErrorsMixin from '@/helpers/errors'

import UiBanner from '@/components/ui/banner'


export default {
  name: 'FormsSubscribe',

  components: {
    UiBanner
  },

  inject: ['$localStorage'],

  mixins: [ 
    ErrorsMixin, 
    FieldsMixin 
  ],

  model: {
    prop: 'user',
    event: 'change',
  },

  props: {
    autofocus: {
      type: Boolean,
      default: true
    },

    errors: {
      type: Object | Array,
    },

    user: {
      type: Object,
      required: true,
    },

    hasGuest: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isCreate: false,
    }
  },

  computed: {
    classes() {
      return {
        'user-forms-subscribe': true,
      }
    },

    errorsLogin() {
      return this.$t('conn3ct-store.user_login_error')
    },

    isCreating() {
      return this.$basil.get(this.user, 'isCreating', false)
    },

    loading() {
      return this.user == null
    },
  },

  methods: {
    onSubmit(e) {
      e.preventDefault()
      return
    }
  },

  mounted() {
    this.user.password = null
    this.user.passwordConfirmation = null

    if(!this.hasGuest) {
      this.isCreate = true
    }
  },
}
</script>
