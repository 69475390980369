import SaylStore from '@/bootstrap/stores'

import ReviewAuth from '../components/checkout/auth.vue'
import ReviewTerms from '../components/checkout/terms'
import ReviewVoucher from '../components/checkout/voucher'
import ReviewPaymentMethods from '../components/checkout/payment-methods'

export default {
  inject: ['$checkout'],
  
  data() {
    return {
      settings: SaylStore.bootstrap().settings
    }
  },

  computed: {
    cards() {
      return [
        {
          attrs: this.$checkout.store().authCard,
          component: ReviewAuth,
          name: 'auth',
          requires: true,
        },
        {
          attrs: this.$checkout.store().voucherCard,
          component: ReviewVoucher,
          name: 'voucher',
          requires: this.isVoucherCardShown,
        },
        {
          attrs: this.$checkout.store().paymentMethodCard,
          component: ReviewPaymentMethods,
          name: 'paymentMethod',
          requires: this.$checkout.store().authCard.valid,
        },
        {
          attrs: this.$checkout.store().termCard,
          component: ReviewTerms,
          name: 'term',
          requires: this.isTermCardShown,
        }
      ]
    },
    // {
    //   attrs: this.invoiceState,
    //   component: ReviewVoucher,
    //   name: 'invoice',
    //   requires: this.isInvoiceCardShown,
    // },

    displayedCards() {
      let ret = []
      ret = this.cards.filter(c => c.requires === true)
      ret.length = ret.indexOf(ret.find(r => !r.attrs.valid)) + 1

      return ret
    },

    // isInvoiceCardShown() {
    //   return (
    //     this.paymentMethodState.valid
    //     && this.$basil.get(this.paymentMethod, 'slug', '') === 'invoice'
    //   )
    // },

    isTermCardShown() {
      return (
        // (
        //   this.paymentMethodState.valid && 
        //   this.$basil.get(this.paymentMethod, 'slug', '') === 'invoice' && 
        //   this.invoiceState.valid
        // ) || 
        (
          this.$checkout.store().paymentMethodCard.valid //&& 
          // this.$basil.get(this.paymentMethod, 'slug', '') !== 'invoice'
        )
      )
    },

    isVoucherCardShown() {
      return (
        (this.$basil.get(this.settings, 'is_voucher_enabled', true))
        // && this.$basil.get(this.paymentMethod, 'slug', '') !== 'invoice'
      )
    },
  },
}
