<template>
  <!-- Logged -->
  <div :class="classes">
    <div class="ui-user__body">
      <div class="ui-user__icon">
        <ui-icon glyph="user"></ui-icon>
      </div>

      <div class="ui-user__inner">
        <div class="ui-user__label">{{ userName }}</div>

        <div
          class="ui-user__sublabel"
          v-if="userEmail"
        >{{ userEmail }}</div>

        <div
          class="ui-user__sublabel"
          v-if="userPhone"
        >{{ userPhone }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthUserLogged',

  inject: [
    '$core',
    '$user'
  ],

  computed: {
    classes() {
      return {
        'ui-user': true,
        'flow': true,
      }
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    userEmail() {
      return this.$basil.get(this.user, 'email')
    },

    userName() {
      let ret = this.$basil.get(this.user, 'firstname')

      if(!this.$basil.isNil(this.user.lastname) && !this.$basil.isEmpty(this.user.lastname)) {
        ret += ` ${this.$basil.get(this.user, 'lastname').toUpperCase()}`
      }
      return ret
    },

    userPhone() {
      return this.$basil.get(this.user, 'phone')
    },
  },
}
</script>
