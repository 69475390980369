<template>
  <card
    class="checkout-auth"
    :has-loader="false"
    v-bind="state"
    @confirm="onConfirm">
    <template v-slot:header>
      <h2 class="checkout-card__title">{{ title }}</h2>

      <actions-button
        class="shop__cta"
        @click="onEdit"
        size="s"
        v-if="!active"
      >{{$t('conn3ct-store.change')}}</actions-button>
    </template>

    <!-- Read -->
    <template v-slot:read>
      <!-- Logged -->
      <article class="checkout-card__article">
        <ui-user />
      </article>
    </template>

    <!-- Edit -->
    <template v-slot:edit>
      <!-- Subscribe form -->
      <article class="checkout-card__article">
        <forms-subscribe
          :autofocus="false"
          :errors="state.errors"
          v-model="user"
          has-guest
        />
      </article>
    </template>
  </card>
</template>

<script>
import ErrorsMixin from '@/helpers/errors'
import FieldsMixin from '../../mixins/fields'

import Card from './card'

import FormsSubscribe from '@/components/forms/subscribe'
import UiUser from '@/components/ui/user'

export default {
  name: 'CheckoutReviewAuth',

  components: {
    Card,
    FormsSubscribe,
    UiUser,
  },

  extends: Card,

  inject: [
    '$checkout',
    '$user',
    '$localStorage'
  ],

  mixins: [
    ErrorsMixin,
    FieldsMixin
  ],

  computed: {
    state() {
      return this.$checkout.store().authCard
    }, 

    title() {
      return this.$t('conn3ct-store.checkout_review_about_you')
    },

    user() {
      return this.$user.user
    },
  },

  methods: {
    isValid() {
      let errors = {}

      if(this.isFieldFirstnameRequired && !this.isFieldFirstnameValid) {
        errors['firstname'] = [this.$t('conn3ct-store.field_is_required')]
      }

      if(this.isFieldLastnameRequired && !this.isFieldLastnameValid) {
        errors['lastname'] = [this.$t('conn3ct-store.field_is_required')]
      }

      if(this.isFieldEmailRequired && !this.isFieldEmailValid) {
        let value = this.$basil.get(this.user, 'email', '') || ''
        let hasValue = value.trim().length > 0
        let isValid = this.emailPattern.test(value)
        errors['email'] = hasValue && !isValid ? [this.$t('conn3ct-store.email_not_valid')] : [this.$t('conn3ct-store.field_is_required')]
      }

      if(this.isFieldPhoneRequired && !this.isFieldPhoneValid) {
        errors['phone'] = [this.$t('conn3ct-store.field_is_required')]
      }

      let errorsKeys = Object.keys(errors)
      let ret = errorsKeys.length === 0

      if(!ret) {
        this.state.errors = errors
      }

      return ret
    },
    
    async onConfirm() {
     if(this.isValid()) {
        try {
          this.state.loading = true
          this.state.errors = {}

          // Update the user
          await this.$user.update({})

          // Update information for the checkout
          await this.$checkout.updateAuthInfo({ comment: null, user: this.user })
          sayl.injekt.sp.userId = this.user.id
          
          this.state.valid = true
          this.state.active = false
          
          this.$localStorage.user = {
            date: new Date().toISOString(),
            email: this.user.email,
            firstname: this.user.firstname,
            lastname: this.user.lastname,
            phone: this.user.phone
          }
        } catch(e) {
          this.state.errors = this.handleErrors(e)
        } finally {
          this.state.loading = false
        }
      }
    },

    onEdit() {
      this.state.active = true
    },

    async reset() {
      this.state.loading = false
      this.state.errors = {}
      this.state.valid = false
      this.state.active = true
    },
  },

  mounted() {
    this.state.errors = {}
    this.reset()
  },
}
</script>
