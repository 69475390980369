<template>
  <card
    class="checkout-voucher"
    :has-loader="false"
    v-bind="state"
    @cancel="onCancel"
    @confirm="onConfirm">
    <template v-slot:header>
      <h2 class="checkout-card__title">{{ title }}</h2>

      <actions-button
        class="shop__cta"
        :loading="state.loading"
        :size="$pepper.Size.S"
        @click="clear"
        v-if="hasVoucher"
      >{{ $t('conn3ct-store.checkout_review_voucher_clear') }}</actions-button>
    </template>

    <!-- Read -->
    <template v-slot:read>
      <!-- Voucher -->
      <article
        class="checkout-card__article checkout-voucher__voucher"
        v-if="hasVoucher">
        <div class="checkout-card__inner checkout-voucher__code">
          <span class="checkout-card__label checkout-voucher__code-name">{{ voucher.name }}</span>

          <data-badge
            class="checkout-voucher__code-discount"
            :value="discount"
            v-if="isVoucher"
          />
        </div>
      </article>

      <!-- No Voucher -->
      <article
        class="checkout-card__article"
        v-else>
        <forms-checkbox
          :size="$pepper.Size.S"
          :value="state.active"
          @change="onEdit"
          v-if="isVoucher"
        >{{ $t('conn3ct-store.checkout_review_voucher_request') }}</forms-checkbox>
      </article>

      <!-- Remaining -->
      <article
        class="checkout-card__article"
        v-if="isWallet">
        <ui-banner
          class="shop__banner"
          :description="$t('conn3ct-store.voucher_wallet_remaining', { amount: remainder })"
        />
      </article>
    </template>

    <!-- Edit -->
    <template v-slot:edit>
      <article class="checkout-card__article">
        <forms-input
          autofocus
          :disabled="state.loading"
          :errors="errors"
          :placeholder="$t(`sayl-front.checkout_review_${name}_placeholder`)"
          @keypress="onKey"
          v-model="code"
        />
      </article>
    </template>

    <template v-slot:footer>
      <div class="row">
        <actions-button
          :disabled="state.loading"
          @click="onCancel"
        >Cancel</actions-button>

        <actions-button
          :appearance="$pepper.Appearance.PRIMARY"
          :disabled="state.loading"
          :loading="state.loading"
          @click="onConfirm"
        >Confirm</actions-button>
      </div>
    </template>

    <!-- Empty the loading of this card -->
    <template #loading><span></span></template>
  </card>
</template>

<script>
import CurrencyMixin from '@/helpers/currency'

import Card from './card'
import UiBanner from '@/components/ui/banner'

export default {
  name: 'CheckoutReviewVoucherCard',

  components: {
    Card,
    UiBanner
  },

  extends: Card,

  inject: [
    '$checkout'
  ],

  mixins: [
    CurrencyMixin
  ],

  props: {
    name: {
      type: String,
      default: 'voucher'
    }
  },

  data() {
    return {
      code: '',
    }
  },

  computed: {
    discount() {
      return this.toCurrency(this.voucher.amount_discount * -1)
    },

    hasVoucher() {
      return !this.$basil.isNil(this.voucher)
    },

    invoiceState() { 
      return this.$checkout.store().voucherCard
    },

    isVoucher() {
      return this.name === 'voucher'
    },

    isWallet() {
      return this.$basil.get(this.voucher, 'type', 'default') === 'wallet'
    },

    remainder() {
      return this.toCurrency(this.voucher.amount_remaining - this.voucher.amount_discount)
    },

    state() {
      if(this.name === 'invoice') {
        return this.invoiceState
      }

      return this.voucherState
    },

    title() {
      return this.$t(`sayl-front.checkout_review_${this.name}_title`)
    },

    voucher() {
      return this.$checkout.voucher.voucher
    },

    voucherState() { 
      return this.$checkout.store().voucherCard
    }
  },

  methods: {
    async clear() {
      try {
        this.state.loading = true
        this.state.errors = []
  
        await this.$checkout.voucher.clear()
        
        this.$bus.$emit('reload-payments')
        this.code = ''
        
        // if(this.name === 'invoice') {
        //   this.state.valid = false
        //   this.state.active = true
        // }
      } catch(e) {
        this.state.errors = this.errors
      } finally {
        this.state.loading = false
      }
    },

    onCancel() {
      this.code = ''
      this.state.errors = []
      this.state.active = false
      this.state.active = (this.name === 'invoice') ? true : false
    },

    async onConfirm() {
      try { 
        this.state.loading = true
        this.state.errors = []
        
        await this.$checkout.voucher.add({ code: this.code })

        this.$bus.$emit('reload-payments')
        this.state.active = false
        this.state.valid = true
      } catch(e) {
        let errors = this.$basil.get(e, '_items', {})
        let ks = Object.keys(errors)
        errors = ks.map(k => this.$t(`sayl-front.checkout_${this.name}_${errors[k][0]}`))
        this.state.errors = errors
        console.error(e)
        $console.error(e)
      } finally {
        this.state.loading = false
      }
    },

    onEdit() {
      this.state.errors = []
      this.state.active = true
    },

    onKey(event) {
      switch(event.keyCode) {
        case 13:
          this.onConfirm()
          break
      }
    },

    reset() {
      if(!this.voucher) {
        return
      }

      this.state.errors = []
      this.code = this.voucher.code
    },
  },

  mounted() {
    this.reset()

    if(this.name === 'invoice') {
      this.clear()
      this.state.errors = []
      this.state.active = true
    }
  }
}
</script>
