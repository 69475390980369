<template>
  <card
    class="checkout-payment-methods"
    v-bind="state"
    @confirm="onConfirm">
    <template v-slot:header>
      <h2 class="checkout-card__title">{{ title }}</h2>
    </template>

    <template v-slot:loading>
      <div class="checkout-payment-methods__loader">
        <ui-loader />
      </div>
    </template>

    <!-- Read -->
    <template 
      v-slot:read 
      v-if="!loading">
      <article class="checkout-card__article">
        <!-- Has methods -->
        <div
          class="checkout-card__inner"
          v-if="hasValues">
          <forms-choice
            :key="key"
            :options="list"
            @change="onChange"
            v-model="value"
          ></forms-choice>
        </div>

        <!-- No Payement methods -->
        <div
          class="checkout-card__inner"
          v-if="!hasValues">
          <p class="checkout-card__label">{{ $t('conn3ct-store.checkout_review_payment_methods_loadingfailed') }}</p>
        </div>
      </article>
    </template>
  </card>
</template>

<script>
import SaylStore from '@/bootstrap/stores'
import Config from '@/config'

import Card from './card'

export default {
  name: 'CheckoutReviewPaymentMethodsCard',

  components: {
    Card
  },

  extends: Card,

  inject: [
    '$checkout',
    '$embed',
  ],
  
  data() {
    return {
      value: null,
      key: 1,
      paymentMethods: null,
      bootstrap: SaylStore.bootstrap()
    }
  },

  computed: {
    checkoutLoading() {
      return this.$checkout.loading
    },

    embed() {
      return this.$basil.get(this.$embed, 'embed.model')
    },

    hasValues() {
      return !!this.list && this.list.length > 0
    },

    i18n() {
      return this.bootstrap.i18n
    },

    list() {
      let ret = []

      if(this.paymentMethods) {
        // if(this.paymentMethods.length === 1 && this.order.base_price_total === 0) {
        //   ret.push({
        //     label: this.$t(`conn3ct-store.${ this.paymentMethods[0].title }`),
        //     value:this.paymentMethods[0].slug,
        //     description: this.$t(`conn3ct-store.${ this.paymentMethods[0].description }`)
        //   })

        //   return ret
        // }
        
        this.paymentMethods.forEach((pm) => {
          let i = {
            label: pm.title === 'cash_payment_for_0_order_title' ? this.$t(`conn3ct-store.${ this.paymentMethods[0].title }`) : pm.title,
            value: pm.slug
          }

          if(pm.description === 'cash_payment_for_0_order_description') {
            i.description = this.$t(`conn3ct-store.${ pm.description }`)
          } else if(!this.$basil.isNil(pm.description)) {
            i.description = this.$basil.get(pm, 'description')
          } else {
            i.description = this.$t(`sayl-front.payment_methods_description_${pm.slug}`)
          }

          ret.push(i)
        })
      }

      return ret
    },

    order() {
      return this.$checkout.order
    },

    paymentMethod() {
      return this.$checkout.payment.paymentMethod
    },

    redirect() {
      let ret = [
        Config.publicPath,
        'checkout/confirm',
        this.order.id
      ]
      ret = ret.filter(e => e.trim().length > 0)
      ret = '/' + ret.join('/')

      let qs = [`service=${this.service.name}`]
      qs = qs.join('&')

      ret = [ret, qs].join('?')
      ret = ret.split('\/\/').join('/')
      ret = ret.replace('//', '/')
      ret = encodeURIComponent(ret)

      return ret
    },

    service() {
      return this.$embed.shop.model.service
    },

    state() {
      return this.$checkout.store().paymentMethodCard
    },

    title() {
      return this.$t('conn3ct-store.checkout_review_payment_methods_title')
    }
  },

  methods: {
    async onChange() {
      let slug = this.value.value
      let method = this.$checkout.payment.getMethodForSlug(slug)

      if(this.$basil.isNil(method)) {
        $console.warn('no payment method for slug', slug)
        this.state.value = false
        return
      }

      try {
        await this.$checkout.payment.setMethod({ method, redirect: this.redirect })
        this.state.valid = true
      } catch(e) {
        this.state.valid = false
      } finally {
        this.key = this.key + 1
      }
    },

    async reset() {
      if(this.$basil.isNil(this.order)) {
        return
      }
      
      try {
        this.state.loading = true
        let pms = await this.$checkout.payment.find({ locale: this.i18n.locale })

        this.paymentMethods = pms
        this.value = this.paymentMethod != null ? this.list.find(p =>  p.value === this.paymentMethod.slug) : null

        if(this.list.length === 1 && this.value == null) {
          this.value = this.list[0]
        }

        if(this.value != null) {
          this.onChange()
        } else {
          this.state.value = false
        }
        this.key++
      } catch(e) {
        this.state.value = false
        this.state.errors = e
      } finally {
        this.state.loading = false
      }
    }
  },

  mounted() {
    this.reset()
  },

  async created() {
    this.$bus.$on('change-lang-payments', () => this.reset())
    
    this.$bus.$on('reload-payments', async () => {
      try {
        // let pms = await this.$checkout.payment.find({ order: this.$checkout.order })
        // this.paymentMethods = pms
        await this.$checkout.payment.setMethod({ method: null })
        await this.reset()
        // this.state.valid = false
        return
      } catch(e) {
        $console.error(e)
      }
    })
  },

  beforeDestroy() {
    this.$bus.$off('reload-payments')
    this.$bus.$off('change-lang-payments')
  }
}
</script>
