<template>
  <card 
    class="checkout-terms"
    :has-loader="false">
    <!-- Read -->
    <template v-slot:read>
      <article class="checkout-card__article -column flow">
        <ui-banner
          :description="$t('conn3ct-store.checkout_review_end_edition')"
          intent="danger"
          v-if="triggered"
        />

        <!-- Terms -->
        <forms-checkbox
          @change="onChange"
          v-model="user.optInTc">
          <div class="view-catalog-checkout__terms-content">
            <span
              class="view-catalog-checkout__terms"
              v-html="$t('conn3ct-store.checkout_review_terms_label')"
            ></span>

            <span
              class="view-catalog-checkout__terms-link"
              @click.stop.prevent="onShowTerms"
              v-html="$t('conn3ct-store.checkout_review_terms_link')"
            ></span>
          </div>
        </forms-checkbox>

        <!-- Newsletter -->
        <div v-if="isNewsletterActive && !optinAlreadyAccepted">
          <forms-checkbox
            @change="onChange"
            v-model="optin">
            <span
              class="view-checkout-review__terms"
              v-html="$t('conn3ct-store.checkout_review_newsletter')"
            ></span>
          </forms-checkbox>
        </div>
      </article>

      <article class="checkout-card__article-cta">
        <actions-button
          appearance="primary"
          class="view-checkout-review__cta"
          :disabled="!isValid || state.loading"
          :key="key"
          :loading="state.loading"
          :size="$pepper.Size.L"
          @click="onConfirm"
        >{{ orderLabel }}</actions-button>
      </article>
    </template>
  </card>
</template>

<script>
import FieldsMixin from '../../mixins/fields'

import Card from './card'
import UiBanner from '@/components/ui/banner'

export default {
  name: 'CheckoutTermsCard',

  components: {
    Card,
    UiBanner
  },

  extends: Card,

  inject: [
    '$checkout',
    '$user',
    '$embed',
  ],

  mixins: [
    FieldsMixin,
  ],

  data() {
    return {
      key: 1,
      triggered: false,
      optin: false
    }
  },

  computed: {
    isNewsletterActive() {
      return this.isFieldActive('has_newsletter')
    },

    isNewsletterOptout() {
      return this.isFieldActive('is_newsletter_optout')
    },

    isOneActive() {
      return this.$checkout.store().actives.length > 0
    },

    isTCOptout(){
      return this.isFieldActive('is_terms_opt_out')
    },

    isValid() {
      return this.key && this.user.optInTc
    },

    label() {
      let ret = this.$t('conn3ct-store.checkout_review_terms')

      let url = null

      if(this.terms) {
        url = `${window.location.origin}/conn3ct-store/${this.$embed.embed.model.id}/terms`
      }

      if(!this.$basil.isNil(url)) {
        ret = this.$t('conn3ct-store.checkout_review_terms_with_link', { link: url })
      }

      return ret
    },

    order() {
      return this.$checkout.order
    },

    orderLabel() {
      let ret = this.$t('conn3ct-store.checkout_order_label')

      if(this.$basil.get(this.paymentMethod, 'slug') === 'cash' || this.$basil.get(this.order, 'price_total', null) === 0) {
        ret = this.$t('conn3ct-store.checkout_place_order_label')
      } else if(!this.$basil.get(this.paymentMethod, 'slug') === 'cash' || this.$basil.get(this.order, 'price_total', null) > 0) {
        ret = this.$t('conn3ct-store.checkout_go_to_payment_label')
      }

      return ret
    },

    paymentMethod() {
      return this.$checkout.payment.item
    },

   
    optinAlreadyAccepted() {
      return this.$basil.get(this.user, 'optIn', false)
    },

    state() {
      return this.$checkout.store().termCard
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },
  },

  methods:{
    onChange() {
      this.key++
    },

    async onConfirm(){
      if(this.isOneActive) {
        this.triggered = true
        return
      }

      try {
        this.user.optIn = this.optin
        this.triggered = false
        this.$bus.$emit('injekt.sp.payment')
        this.state.loading = true

        // await this.$user.update({})
        // await this.$checkout.updateAuthInfo({ comment: this.order.comment, user: this.user })
        
        await this.$checkout.payment.apply({ order: this.order })
        await this.$checkout.confirm()
      } catch(e) {
        this.$notification({
          title: this.$t('conn3ct-store.error_confirm_title'),
          message: this.$t('conn3ct-store.error_confirm_description'),
          type: 'error'
        })
      } finally {
        this.state.loading = false
      }
    },

    onShowTerms() {
      this.$bus.$emit('terms')
    }
  },

  mounted() {
    this.user.optInTc = this.isTCOptout
    this.optin = this.user.optIn || this.isNewsletterOptout
    this.$checkout.store().loading = false
    this.key++
  }
}
</script>
