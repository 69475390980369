<template>
  <section :class="classes">
    <div class="checkout-card__content">
      <!-- Header -->
      <header
        class="checkout-card__header"
        v-if="hasHeader">
        <slot name="header"></slot>
      </header>

      <section
        class="checkout-card__subtitle"
        v-if="hasSubtitle"
      ><slot name="subtitle"></slot></section>

      <!-- Body -->
      <transitions-fade
        direction="down"
        mode="out-in">
        <!-- Edit -->
        <div
          class="checkout-card__body checkout-card__edit"
          key="edit"
          v-if="active">
          <slot name="edit"></slot>
        </div>

        <!-- Read -->
        <div
          class="checkout-card__body checkout-card__read"
          key="read"
          v-if="!active && has('read')">
          <slot name="read"></slot>
        </div>
      </transitions-fade>

      <!-- Loading -->
      <div
        class="checkout-card__loader"
        v-if="loading && hasLoader">
        <slot name="loading">
          <ui-loader />
        </slot>
      </div>
    </div>

    <!-- Footer -->
    <transitions-fade
      direction="up"
      mode="out-in">
      <footer
        class="checkout-card__footer"
        v-if="active">
        <slot name="footer">
          <actions-button
            appearance="primary"
            :loading="loading"
            :size="$pepper.Size.L"
            @click="onConfirm"
          >{{ $t('conn3ct-store.confirm') }}</actions-button>
        </slot>
      </footer>
    </transitions-fade>
  </section>
</template>

<script>
export default {
  name: 'CheckoutCard',

  props: {
    active: {
      type: Boolean,
      default: false
    },

    errors: {
      type: Object|Array
    },

    hasLoader: {
      type: Boolean,
      default: true,
    },  

    loading: {
      type: Boolean,
      default: false
    },

    pristine: {
      type: Boolean,
      default: true
    },

    required: {
      type: Boolean,
      default: false
    },

    valid: {
      type: Boolean,
      default: true
    },
  },

  computed: {
    classes() {
      return {
        'checkout-card': true,

        '-is-active': this.active === true,
        '-is-loading': this.loading === true,
        '-is-errored': this.errored === true
      }
    },

    errored() {
      return !this.$basil.isNil(this.errors) &&
             !this.$basil.isEmpty(this.errors)
    },

    hasHeader() {
      return this.has('header')
    },

    hasSubtitle() {
      return this.has('subtitle')
    }
  },

  methods: {
    has(name) {
      let items = this.$slots[name]
      let ret = !!items || (items && items.length > 0)

      if(ret > 0) {
        ret = items.find((i) => i.isComment === false && (!!i.context || (i.text && i.text.trim().length > 0)) ) !== undefined
      }

      return !!ret
    },

    onConfirm() {
      return this.$emit('confirm')
    }
  }
}
</script>
